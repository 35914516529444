<template>
  <div class="col-md-8 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>{{ title }}</h1>
        </div>
        <br>
        <form v-on:submit.prevent="saveItem()">
          <!--
          <div class="form-group">
            <label>Compañía (*):</label>
            <input type="text" class="form-control" v-model="item.cmp_id" disabled="true">
          </div>
        -->
          <div class="form-row">
            <div class="form-group col-md-6">
              <label>Tipo de tabla (*):</label>
              <select v-model="item.tta_id" class="form-control" required autofocus>
                <option v-for="tpotabla in tpotablas" v-bind:value="tpotabla._id">
                  {{ tpotabla.tta_nmbre }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label>Versión (*):</label>
              <input type="number" class="form-control" v-model="item.tav_nmro" required>
            </div>
          </div>
          <div class="form-group">
            <label>Fecha Inicio (*):</label>
            <input type="date" class="form-control" v-model="inicio" required>
          </div>
          <div class="form-group">
            <label>Acta (*):</label>
            <input type="text" class="form-control" v-model="item.tav_acta" required>
          </div>
          <div class="form-group">
            <label>Descripción (*):</label>
            <input type="text" class="form-control" v-model="item.tav_descripcion">
          </div>
          <br />
          <div v-if="message">
            <p class="alert alert-warning" role="alert">{{ message }}</p>
          </div>
          <div class="row text-center">
            <div class="col-4">
              <button type="submit" class="btn btn-primary" v-on:click="setClose(true)">{{ buttontext }}</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-success" v-on:click="setClose(false)">Guardar</button>
            </div>
            <div class="col-4">
              <button type="text" class="btn btn-secondary" v-on:click.prevent="cancel()">Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  import { dateToUtc } from '../../utils';

  export default {
    data(){
      return{
        add: true, //Create a new document
        close: true, // Close the page and return to list
        title: '',
        item: {},
        message: '',
        buttontext: '',
        tpotablas: [],
        inicio: null
      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      },
      cancelButtonVisibility() {
        if (this.addInd) {
          return 'visibility:hidden'
        } else {
          return 'visibility:visible'
        }
      }
    },
    created: function() {
      this.fetchTpotablas();

        this.add = this.$route.params.add;
        if (this.add){
          this.title = 'Crear una Versión de Tabla';
          this.buttontext = 'Agregar Versión de Tabla';
        } else {
          this.title = 'Editar una Versión de Tabla';
          this.buttontext = 'Actualizar Versión de Tabla';
          this.getItem(this.$route.params.id);
        }
    },
    methods: {
      setClose(close){
        this.close = close;
      },
      fetchTpotablas()
      {
        let uri = '/tpotablas/' + String(this.$store.state.company);
        this.axios.get(uri).then((response) => {
          this.tpotablas = response.data;
        });
      },
      saveItem(){
        //var d = new Date(this.inicio);
        /*
        if(d.getTimezoneOffset() > 0){
            d.setTime( d.getTime() + d.getTimezoneOffset()*60*1000 );
        }
        */
        this.item.tav_inicio = this.inicio;

        if (this.add){
          this.item.cmp_id = String(this.$store.state.company);
          let uri = '/tabvers/add';
          //alert(this.item);
          this.axios.post(uri, this.item)
          .then(response => {
            console.log(response);
            this.$router.replace({ name: 'DisplayTablaVersion' });
            this.message = 'Registro guardado';
          })
          .catch(err => {
            console.log(err);
            this.message = '¡Error al crear el registro! ' + err;
          });
        }
        else {
          let uri = '/tabvers/update/' + this.item._id;
          this.axios.post(uri, this.item).then((response) => {
            if (this.close){
              this.$router.push({name: 'DisplayTablaVersion'});
            }
            this.message = 'Registro guardado';
          });
        }
      },
      getItem(id)
      {
        let uri = '/tabvers/edit/' + id;
        this.axios.get(uri).then((response) => {
          this.item = response.data;
          this.inicio = this.item.tav_inicio.toString().substring(0,10);
        });
      },
      cancel(){
        this.$router.replace({name: 'DisplayTablaVersion'});
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
